import { classNames } from "@/services/utils"

const Separator = function ({ children, className }) {
  const styles = classNames(
    "flex text-gray-700 my-7 tablet:my-2.5 justify-center items-center after:content-[''] before:content-[''] after:border-b after:border-gray-700 before:border-b before:border-gray-700 after:flex-1 before:flex-1 after:ml-4 before:mr-4",
    className
  )
  return <p className={styles}>{children}</p>
}

export default Separator
