import Icon, { IconImg } from "./Icon"

const WECHAT_APP_ID = process.env.NEXT_PUBLIC_WECHAT_APP_ID

function getLoginUrl(action) {
  const redirectUrl = `${location.origin}/api/wechat-login`
  return `https://open.weixin.qq.com/connect/qrconnect?appid=${WECHAT_APP_ID}&redirect_uri=${encodeURIComponent(
    redirectUrl
  )}&response_type=code&scope=snsapi_login&state=${action}`
}

const Wechat = function ({ action }) {
  function onClick() {
    const url = getLoginUrl(action)
    location.href = url
    return
  }
  return (
    <Icon onClick={onClick}>
      <IconImg src="/icon/wechat.svg" alt="wechat" />
    </Icon>
  )
}

export default Wechat
