import { Fragment } from "react"
import Script from "next/script"
import Icon, { IconImg } from "./Icon"
const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID

const Facebook = function ({ onClick }) {
  return (
    <Fragment>
      <Script
        strategy="afterInteractive"
        src={`https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v13.0&appId=${FACEBOOK_APP_ID}&autoLogAppEvents=1`}
      />
      <Icon onClick={onClick}>
        <IconImg src="/icon/facebook.svg" alt="facebook" />
      </Icon>
    </Fragment>
  )
}

export default Facebook
