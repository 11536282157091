import AppleSignin from "react-apple-signin-auth"
import Icon, { IconImg } from "./Icon"
import jwtDecode from "jwt-decode"
const APPLE_CLIENT_ID = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID
const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN

const Apple = function ({ onSuccess, onError }) {
  const redirectURI = `${DOMAIN}/callback`

  function successCallback(res) {
    if (res.authorization?.id_token) {
      const jwtPayload = jwtDecode(res.authorization.id_token)
      return onSuccess(jwtPayload)
    }
    onError({
      message: "token missing",
    })
  }
  return (
    <AppleSignin
      authOptions={{
        clientId: APPLE_CLIENT_ID,
        scope: "email name",
        redirectURI: redirectURI,
        usePopup: true,
      }}
      render={(props) => (
        <Icon onClick={props.onClick}>
          <IconImg src="/icon/apple.svg" alt="apple" />
        </Icon>
      )}
      onSuccess={successCallback}
      onError={onError}
    />
  )
}

export default Apple
