import { useRouter } from "next/router"
import React, { Fragment, useEffect } from "react"
import { useMultilang } from "@/hooks/useMultilang"
import { useDispatch } from "react-redux"
import { Google, Facebook, Apple, Wechat } from "./"
import { sendNotification } from "@/services/utils"
import * as authentication from "@/services/authentication"
import { createSocialAccount } from "@/store/oauth"
import { createOauthPayload } from "@/services/utils"
import { SIGNUP } from "@/constants/route"
import { verifyOAuth } from "@/services/authentication"
import { doUserLogin } from "@/services/utils/authentication"

const createGooglePayload = createOauthPayload("google")
const createFacebookPayload = createOauthPayload("facebook")
const createApplePayload = createOauthPayload("apple")
const createWechatPayload = createOauthPayload("wechat")

async function hasValidAccount(oauthPayload) {
  const res = await verifyOAuth(oauthPayload)
  const accountExists = res?.exist
  const isValid = accountExists && oauthPayload.oauthUserId

  return isValid
}

async function checkEmailExist(email) {
  const res = await authentication.verifyEmailExist(email)

  return res.exist
}

function OAuth({ debug, wechatAction }) {
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useMultilang()
  const loginUser = doUserLogin({ router, debug })

  function onError(e) {
    debug("onError", e)
    sendNotification(t("signup.oauth-fail"), "error")
  }

  async function handleLogin(payload) {
    const { condition, debugReason, ...data } = payload

    return loginUser(data).catch(() =>
      sendNotification(t("login.oauth-fail"), "error")
    )
  }

  async function handleSignup(payload) {
    const { condition, debugReason, ...data } = payload

    if (!condition) {
      return onError(debugReason)
    }

    const email = payload?.email
    const emailExists = email ? await checkEmailExist(email) : false

    if (emailExists) {
      return sendNotification(t("error-msg.email-exist"), "error")
    }

    dispatch(
      createSocialAccount({
        data,
        skipVerifyEmail: email,
      })
    )

    if (router.pathname !== SIGNUP) {
      router.replace(SIGNUP)
    }
  }

  async function googleHandler(data) {
    debug(data)
    const email = data?.email || ""
    const payload = createGooglePayload(data?.sub)
    const hasAccount = await hasValidAccount(payload)
    const authenticationHandler = hasAccount ? handleLogin : handleSignup

    authenticationHandler({
      ...payload,
      email,
      condition: data?.sub,
      debugReason: "missing googleId",
    })
  }

  async function facebookHandler(data) {
    debug(data)
    const userID = data?.authResponse?.userID
    const payload = createFacebookPayload(userID)
    const hasAccount = await hasValidAccount(payload)
    const authenticationHandler = hasAccount ? handleLogin : handleSignup

    authenticationHandler({
      ...payload,
      condition: userID,
      debugReason: "missing userID",
    })
  }

  async function appleHandler(data) {
    debug(data)
    const email = data?.email || ""
    const payload = createApplePayload(email)
    const hasAccount = await hasValidAccount(payload)
    const authenticationHandler = hasAccount ? handleLogin : handleSignup

    authenticationHandler({
      ...payload,
      email: data?.is_private_email ? "" : email,
      condition: email,
      debugReason: "missing email",
    })
  }

  async function wechatHandler(wechatId) {
    debug(wechatId)
    const payload = createWechatPayload(wechatId)
    const hasAccount = await hasValidAccount(payload)
    const authenticationHandler = hasAccount ? handleLogin : handleSignup

    authenticationHandler({
      ...payload,
      condition: wechatId,
      debugReason: "missing wechatId",
    })
  }

  useEffect(() => {
    if (router.query.wechat_id) {
      wechatHandler(router.query.wechat_id)
    }
  }, [router.query.wechat_id, wechatHandler])

  return (
    <Fragment>
      <Google onSuccess={googleHandler} onError={onError} />
      <Facebook onClick={() => window.FB.login(facebookHandler)} />
      <Apple onSuccess={appleHandler} onError={onError} />
      <Wechat action={wechatAction} />
    </Fragment>
  )
}

export default OAuth
