import * as authentication from "@/services/authentication"
import { HOME, PROFILE_SUBSCRIPTION } from "@/constants/route"
import { KEYS, local } from "@/services/utils/storage"
import { mutate } from "swr"
import { userSubscriptionDetailEndpoint } from "@/services/subscription"
import { getIsPostGracePeriod } from "@/components/Profile/Subscription/Modal/utils"

export function doUserLogin({ router, debug }) {
  return async function (data) {
    try {
      const loginData = await authentication.loginUser(data)
      const token = loginData?.token
      const prevRoute = router.query?.ref
      const baseRoute = prevRoute ? prevRoute : HOME

      local.set(KEYS.ACCESS_TOKEN, token)
      await authentication.mutateLoginUser()
      await mutate(authentication.getPaidEndpoint)
      const userSubscription = await mutate(userSubscriptionDetailEndpoint)
      const isPostGracePeriod = getIsPostGracePeriod(userSubscription?.status)
      const nextRoute = isPostGracePeriod ? PROFILE_SUBSCRIPTION : baseRoute
      await router.push(nextRoute)
    } catch (error) {
      debug("error occurred during user login", error)
      const message = error?.response?.data?.message
      const code = error?.response?.data?.code
      const isEmailOrPasswordIncorrect =
        message === "login fail" && code === 401

      if (isEmailOrPasswordIncorrect) {
        throw error
      }
    }
  }
}
